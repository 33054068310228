import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/Homepage.css";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import aboutlap from "./Homepage/Images/aboutlap.svg";

const CountdownTimer = ({
  initialDays = 0,
  initialHours = 0,
  initialMinutes = 1,
  initialSeconds = 0,
}) => {
  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0 && minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else if (minutes === 0 && hours > 0) {
        setHours(hours - 1);
        setMinutes(59);
        setSeconds(59);
      } else if (hours === 0 && days > 0) {
        setDays(days - 1);
        setHours(23);
        setMinutes(59);
        setSeconds(59);
      } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(timerInterval);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [days, hours, minutes, seconds]);

  return (
    <div className="countdown-container">
      <h1 className="countdown-heading">Launching In</h1>
      <div className="timer">
        <div className="time-container">
          <span className="time">{days < 10 ? `0${days}` : days}</span>
          <span className="label">Days</span>
        </div>
        <span className="colon">:</span>
        <div className="time-container">
          <span className="time">{hours < 10 ? `0${hours}` : hours}</span>
          <span className="label">Hours</span>
        </div>
      </div>
    </div>
  );
};

const Aboutus = () => {
  return (
    <div>
      <Navbar />
      <div className="about-banner">
        <h2>About Us</h2>
        <p>
          Edge is a cutting-edge fintech company revolutionizing the way you
          manage your money. With our innovative card solutions, we offer
          seamless payments, instant rewards, and financial empowerment—all in
          one sleek, smart card. Whether you’re a business professional or a
          savvy consumer, Edge is here to make every transaction smoother and
          more rewarding. Join us and experience the future of finance today!
        </p>
      </div>
      <div className="launching-sec">
        <CountdownTimer
          initialDays={10}
          initialHours={1}
          initialMinutes={30}
          initialSeconds={45}
        />
      </div>
      <div className="about-white-line">
        <div className="about-des container pt-5">
            <h3>Empowering Your Financial Future</h3>
            <p>Our mission is to fundamentally transform the way people interact with their finances by providing innovative, smart, accessible, and secure financial tools that not only streamline everyday transactions but also empower individuals through enhanced financial literacy and confidence.</p>
        </div>
        <div className="lap-img pb-5">
            <img src={aboutlap} alt="About Us" className="img-fluid" />
        </div>
        <div className="about-location py-5">
            <div className="about-des-location container mx-auto">
            <h3>Our Location</h3>
            <p>Shop No. 203 II Floor, Hiltan Arcade Shopping Center, Evershine CityGokhivare,  Vasai East, Vasai, Mumbai - 401208.</p>
            </div>
            
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
