import './App.css';
import Homepage from './pages/Homepage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/Aboutus';       // Make sure the path is correct
import Contact from './pages/Contact';   // Make sure the path is correct

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </Router>  );
}

export default App;
