import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/Homepage.css";
import edge from "./Homepage/Images/edge.png";
import { FaGlobe } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import eng from "./Homepage/Images/eng.png";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="navbar-all  mx-auto">
        <div className="nav-con container mx-auto">
          <div className="nav-right-con">
            <Link to="/">
              <img src={edge} alt="Edge" className="img-fluid mx-auto " />
            </Link>
            <ul className="nav-list">
              <li className="nav-item">Our Products
                {/* <ul className="dropdown">
                  <li>Product 1</li>
                  <li>Product 2</li>
                  <li>Product 3</li>
                </ul> */}
              </li>
              <li className="nav-item"><Link to="/contact">Help Center</Link>
              </li>
              <li className="nav-item"><Link to="/about">About</Link>
              </li>
            </ul>
          </div>

          <div className="nav-left-con">
            <div className="eng">
              {/* <FaGlobe className="globe" /> */}
              {/* <img src={eng} alt="Edge" className="img-fluid mx-auto " /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.747 9.41505 20.7188 6.93683 18.891 5.109C17.0632 3.28116 14.585 2.25298 12 2.25ZM20.25 12C20.2507 12.7608 20.1456 13.5181 19.9378 14.25H16.3275C16.5576 12.7588 16.5576 11.2412 16.3275 9.75H19.9378C20.1456 10.4819 20.2507 11.2392 20.25 12ZM9.5625 15.75H14.4375C13.9573 17.3237 13.1235 18.7667 12 19.9688C10.877 18.7663 10.0434 17.3234 9.5625 15.75ZM9.19688 14.25C8.93931 12.7611 8.93931 11.2389 9.19688 9.75H14.8106C15.0682 11.2389 15.0682 12.7611 14.8106 14.25H9.19688ZM3.75 12C3.74934 11.2392 3.85441 10.4819 4.06219 9.75H7.6725C7.44244 11.2412 7.44244 12.7588 7.6725 14.25H4.06219C3.85441 13.5181 3.74934 12.7608 3.75 12ZM14.4375 8.25H9.5625C10.0427 6.67631 10.8765 5.23328 12 4.03125C13.123 5.23367 13.9567 6.67657 14.4375 8.25ZM19.3434 8.25H16.0041C15.5831 6.70587 14.8738 5.25534 13.9134 3.975C15.0738 4.25375 16.1602 4.7801 17.098 5.51799C18.0359 6.25588 18.8032 7.18784 19.3472 8.25H19.3434ZM10.0866 3.975C9.12623 5.25534 8.41687 6.70587 7.99594 8.25H4.65282C5.19682 7.18784 5.96407 6.25588 6.90196 5.51799C7.83985 4.7801 8.92621 4.25375 10.0866 3.975ZM4.65282 15.75H7.99594C8.41687 17.2941 9.12623 18.7447 10.0866 20.025C8.92621 19.7463 7.83985 19.2199 6.90196 18.482C5.96407 17.7441 5.19682 16.8122 4.65282 15.75ZM13.9134 20.025C14.8738 18.7447 15.5831 17.2941 16.0041 15.75H19.3472C18.8032 16.8122 18.0359 17.7441 17.098 18.482C16.1602 19.2199 15.0738 19.7463 13.9134 20.025Z" fill="white"/>
</svg>

              <p>Eng</p>
            </div>
            {/* <div className="login">
              <p>Log In</p>
            </div> */}
            <div className="login">
  <a href="https://dashboard.edgepay.in/page/pay/login?returnTo=%2Fdashboard" target="_blank" rel="noopener noreferrer">
    <p>Log In</p>
  </a>
</div>

            <div className="register">
              <Link to="https://dashboard.edgepay.in/page/pay/login?returnTo=%2Fdashboard" target="_blank" rel="noopener noreferrer">
                <button type="button">Register</button>
              </Link>
            </div>

            <div className="bar">
              <FaBarsStaggered onClick={toggleSidebar} />
            </div>
            
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <img src={edge} alt="Edge" className="img-fluid mx-auto pb-5 ps-3 " />

              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/about">About Us</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </div>
            
            {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
