import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/Homepage.css";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import formcontact from "./Homepage/Images/formcontact.svg";

const CountdownTimer = ({
  initialDays = 0,
  initialHours = 0,
  initialMinutes = 1,
  initialSeconds = 0,
}) => {
  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0 && minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else if (minutes === 0 && hours > 0) {
        setHours(hours - 1);
        setMinutes(59);
        setSeconds(59);
      } else if (hours === 0 && days > 0) {
        setDays(days - 1);
        setHours(23);
        setMinutes(59);
        setSeconds(59);
      } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(timerInterval);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [days, hours, minutes, seconds]);
  

  return (
    <div className="countdown-container">
      <h1 className="countdown-heading">Launching In</h1>
      <div className="timer">
        <div className="time-container">
          <span className="time">{days < 10 ? `0${days}` : days}</span>
          <span className="label">Days</span>
        </div>
        <span className="colon">:</span>
        <div className="time-container">
          <span className="time">{hours < 10 ? `0${hours}` : hours}</span>
          <span className="label">Hours</span>
        </div>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <div>
      <Navbar/>
      <div className="about-banner">
        <h2>Contact Us</h2>
        <p>
        At Edge Fintech, we're on a mission to make finance smarter, simpler, and more accessible for everyone. We provide secure, user-friendly financial tools that streamline transactions and promote financial literacy. Whether you’re an individual or a business, our goal is to empower you with the resources you need to manage your finances with confidence. Welcome to the future of finance—simple, secure, and accessible.
        </p>
      </div>
      <div className="launching-sec">
        <CountdownTimer
          initialDays={10}
          initialHours={1}
          initialMinutes={30}
          initialSeconds={45}
        />
      </div>
      <div className="form-contact container mx-auto py-5">
        <div className="row">
            <div className="col-md-7 col-12">
                <h3>Get in touch with us</h3>
                <p>Drop us an email with your questions, suggestions, or even just to say hello. We'll get back  to you at the earliest.</p>
                <form >
      <div>
        {/* <label>Name:</label> */}
        <input 
          type="text" 
          name="name" 
          placeholder="Enter your mail"
         
          required
        />
      </div>
      <div>
        {/* <label>Email:</label> */}
        <input 
          type="email" 
          name="email" 
          placeholder="Enter your name"
        
          required
        />
      </div>
      <div>
        {/* <label>Message:</label> */}
        <textarea 
          name="message" 
          placeholder="Type your message here.."
         
          required
        />
      </div>
      <div>
        <button type="submit">Submit</button>
      </div>
    </form>


            </div>
            <div className="col-md-5 col-12 mt-auto">
            <img src={formcontact} alt="About Us" className="img-fluid" />

            </div>

        </div>
        
      </div>
      <Footer/>
    </div>
  )
}

export default Contact
